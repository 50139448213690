import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
//import { navigate } from "gatsby";
import { Form, Formik, Field } from "formik";
import Layout from "../Components/Layout";
import Box from "../Components/Box2";
import Input from "../Components/Input";
import Checkbox from "../Components/Checkbox";
import { RaisedButton } from "../Components/Buttons";
import 'react-datepicker/dist/react-datepicker.css';
import Owl from "../assets/images/peleda 1.png";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';




export default () => {

  const [data, setData] = useState([]);
  const [inputValueMok, setInputValueMok] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [serverResponse, setServerResponse] = useState(null)

  const [value2, setValue2] = useState("1");

  const optionClass = [
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
  ]

  let SubMsg = 1;



  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    //if (inputValueMok) {
      fetch(`https://dzk.lrt.lt/egzaminas/api/search2`)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          const formattedData = result.map(item => ({
            value: item.id,
            label: item.name
          }));
          setData(formattedData);
        })
        .catch(error => console.log('Error fetching data:', error));
    //}
  }, [inputValueMok]);

  const handleChange = (value) => {
    setSelectedValue(value);


  };

  const handleInputChange = (inputValueMok) => {
    setInputValueMok(inputValueMok);
  }

  return (
    <Layout>
      {(SubMsg !== 0) && (
        <div className="registration-page">
          <div className="registration-success">
            <h3 style={{color: "#5dc5cc"}}>SVEIKINAME,</h3>
            <h4>JŪS SĖKMINGAI UŽSIREGISTRAVOTE Į <br/>
            DEMOKRATIJOS ŽINIŲ KONKURSĄ.<br/>
            PATVIRTINKITE SAVO PASKYRĄ,<br/>
            SPUSTELDAMI NUORODĄ EL. LAIŠKE, KURĮ JUMS IŠSIUNTĖME.<br/>
            BALANDŽIO 16-17 D. 7-23.59 VAL. KONKURSO KLAUSIMUS GALĖSITE<br/>
            SPRĘSTI PRISIJUNGĘS SU SAVO<br/>
            EL. PAŠTU.</h4>
          </div>
        </div>
      )}
    </Layout>
  )
}
